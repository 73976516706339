* {
    --color-lilac: #BDB2C3;
    --color-lilac-dark: #545166;
    --color-white: #FFFFFF;
    --color-gray: #46454C;
    --color-gray-light: #F0F0F3;
    --color-panel: rgba(255, 255, 255, 0.8);
    --color-gray-a2: #00000006;
    --color-gray-a5: #0000001f;
    --color-black-a2: rgba(0, 0, 0, .1);
    --color-gray-semi: #636363;
}