@import "assets/css/variables.css";
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fancybox__container {
  pointer-events: auto;
}

@font-face {
  font-family: "Main";
  src: url(./assets/fonts/main.otf);
}

* {
  font-family: 'Main', 'Roboto', sans-serif;
  /* letter-spacing: 0.2px; */
}

.lv-booking-module {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  border: none;
}